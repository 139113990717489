<template>
  <div class="rule">
    <div class="header">
      <Header />
      <div class="header-text">
        <div class="header-title">
          服务条款
        </div>
        <div class="header-sign">
          为了更好的为您提供服务，请您仔细阅读以下条款，理解认同后再进行注册！
        </div>
      </div>
    </div>
    <div class="rule-content">
      欢迎访问新量象互动广告平台（www.xinliagnxiang.com）
      在注册、使用新量象互动广告平台（下文简称“新量象平台”）前，请您务必仔细阅读本注册协议，尤其是本注册协议中以黑体、下划线着重提示的条款、收益结算、违约责任、免责条款、管辖权等与您权益有关的内容。一旦您在“我已阅读并同意《新量象平台媒体注册协议》”前打“√”或开始以其他方式使用新量象平台，即表明您已充分阅读并完全理解本注册协议项下的全部条款，同意将其作为确定您与新量象间权利义务的依据。如您不同意本注册协议或其中任何条款约定，您应该停止注册程序，并不得以任何形式访问我们的服务。<br>
      您有违反本注册协议的任何行为时，新量象有权依照您违反情况随时采取限制、中止或终止向您提供服务等措施，并有权追究您的相关责任。<br>
      本注册协议内容包含现在及将来新量象通过网络或通讯方式告知您的各项操作提示、规则、公告、说明等文本（以下统称“服务规则”），所有服务规则均为本注册协议不可分割的一部分，与本注册协议具有同等法律效力。<br>
      新量象将根据业务场景及监管要求不时修改本注册协议，请您注意查看。注册协议自更新、公示后即产生效力。如果您对更新后的注册协议有任何疑问，请通过“第十三条
      其他”条款展示的联系方式联系我们。 <br>
      一、定义 <br>
      1.1 新量象：具体是指杭州新量象网络科技有限公司及其关联公司，包括但不限于：霍尔果斯新量象网络科技有限公司、霍城兑捷网络科技有限公司，本注册协议中也称“新量象平台”或“我们”。<br>
      1.2 新量象平台：是指由新量象自主研发的互动广告平台，可为广告主提供流量采购、营销推广等服务，为流量主（媒体）提供流量价值、费用统计、数据查询等服务。<br>
      1.3 媒体：指为发布、展示广告提供相关页面、位置的网站、移动应用程序等互联网产品（以下简称“服务平台”）的开发者、运营者或取得合法授权的代理商，即本注册协议中的“您”或“用户”。<br>
      1.4 终端用户：浏览、点击服务平台展示信息的个人用户，包括服务平台的注册用户及非注册用户。<br>
      1.5 推广素材：指广告主自行设计制作或依法委托他人设计制作的，用于展示其享有合法权利的产品、服务的信息内容，包括但不限于图片、文字、视频、flash等，包括落地页本身<br>
      1.6 虚假流量：指为提高、骗取收益，通过人工、脚本以及其他不合法或不正当的方式制造的服务效果数据（包括但不限于：页面访问量、点击量、曝光量等推广效果数据）。<br>
      二、用户注册与注销 <br>
      2.1 用户注册：请按照“注册为媒体”的页面提示，完整、真实的填写各项信息，并选择新量象媒体账户类型。<br>
      2.2 新量象媒体账户说明 <br>
      2.2.1 新量象媒体账户分为“直客”和“代理商”，分别有如下含义：<br>
      a）直客是指：发布、展示推广素材的应用程序、网站等服务平台的开发者或运营方，能够自主决定是否发布、展示或下线推广素材；<br>
      b）代理商：与直客签署相关合作协议，委托直客发布、展示推广素材的经营主体。<br>
      2.2.2 为了您的账户安全，请设置较为复杂的密码。除非有法律规定或司法裁定，且征得新量象的同意，否则，新量象账户不能以任何方式转让、赠与或继承。因用户自身的疏忽而导致账户信息泄露等后果，由用户自行承担。<br>
      2.2.3 您取得新量象账户后，应当根据新量象平台的页面提示或新量象工作人员的指引向新量象提供相关证明文件。经新量象或新量象合作认证机构的审核通过后，您才能完整使用新量象平台的各项功能。否则，您使用新量象平台或与新量象开展合作将受到限制。<br>
      2.2.4 任何通过您的新量象账户实施的操作，均视为是您的操作，由您承担全部责任。如果您发现任何非法使用您的新量象账户的行为的，应立即通知新量象。<br>
      2.3 用户授权：为核验您所提供的信息及资料的真实性，您同意并授权新量象及新量象合作的签约平台或认证机构获取、使用、保存您提供的信息，包括：公司主体信息、联系人的个人信息、营业执照副本及其他资质证明文件。<br>
      2.4 用户注销：若您需要注销账户，应与新量象联系。账户注销后，您与新量象间就新量象平台相关的所有协议均自动解除，但您仍然应对您在使用新量象平台期间的行为对新量象或第三方造成的任何损失承担可能的违约或损害赔偿责任。<br>
      三、用户的保证与承诺 <br>
      3.1 您保证，您在新量象平台上填写、提交的全部信息真实、完整、准确、有效。因您填写错误信息、或者填写正确信息但是与您通过其他书面方式在新量象预留的企业信息不一致而产生的一切后果，由您独立承担。<br>
      3.2 您保证，您在新量象平台填写信息时，尽最大注意义务防止您所填写的信息泄露，并以正确步骤离开相关页面。若您发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知新量象。<br>
      3.3 您承诺，您在使用新量象平台时所实施的全部操作，不违反国家法律、法规、社会公共利益、新量象与您签署的其他协议或文本、及新量象其他有关规定，并且不侵犯第三人的合法权益。您利用新量象平台从事非法活动或不正当交易产生的一切后果和法律责任，由您独立承担。<br>
      四、用户的权利与义务 <br>
      4.1 您有权使用新量象平台页面展示的各项功能，包括但不限于：流量价值、收益统计、数据分析等，并选择接入方式SDK/API等。您与新量象间的收益结算参见本注册协议中的第七条
      收益结算。 <br>
      4.2 您与新量象合作前，还应签署合作协议，签署形式包括：签署纸质合同、或以数据电文的形式订立电子合同。就您与新量象合作期间的收益结算、权利与义务等具体约定，与本注册协议不一致的，以您与新量象签署的合作协议为准。<br>
      4.3 您保证对服务平台享有合法权利，由您或您服务平台提供的相关内容不违反国家的有关政策、法律、法规，不侵犯第三方权利人的合法权益。如违反上述要求引起的一切后果由您独立承担，因此给新量象、其他用户或第三方造成损失的，由您承担最终赔偿责任。<br>
      4.4 您应向新量象提供推广内容接入所需的一切资源及信息，包括但不限于程序嵌入详细说明及教程、信息后台系统账号信息、客服联系人信息等。<br>
      4.5 您应严格执行推广素材的发布计划，提供稳定可靠的发布服务，保证推广素材按时在确定的位置上进行充分展现。<br>
      4.6 您负责对服务平台的运行管理，保证服务平台的稳定性，并负责服务平台的用户咨询、投诉等事宜。<br>
      4.7 您应规范的使用新量象平台的各项功能，包括但不限于：遵守新量象合作规范（详细见本注册协议第五条关于虚假流量的处理规则）、不得对新量象平台进行复制、翻译、修改、反汇编、出售、转租等侵犯新量象合法权利的行为。<br>
      五、关于虚假流量的处理规则 <br>
      5.1 您理解并同意，对推广素材的点击应来自对此真正感兴趣的终端用户，以任何形式制造、向新量象提供虚假流量，均属于严重违反本注册协议的行为。制造、提供虚假流量的违规行为，包括但不限于如下情形：<br>
      5.1.1 通过人工、脚本及非法技术手段等方式，伪造流量，比如：<br>
      a）以人工操作的方式，进行反复点击、强制点击、恶意点击等行为；<br>
      b）通过程序或脚本或自动点击机器等方式模拟访问用户的访问、点击等操作，包括但不限于：程序自动点击、自动刷新、自动浏览等情形；<br>
      c）通过违规嵌入的形式，形成流量多次售卖等情况； <br>
      5.1.2 通过诱导终端用户点击的方式，虚增流量，比如：<br>
      a）用文字或者图形诱导用户对广告内容进行点击；<br>
      b）使终端用户对广告与内容产生混淆、或者故意引导误触、误点击；<br>
      c）在非激励型广告合作形式中，对终端用户点击行为进行资源交换、奖励。 <br>
      5.1.3 向新量象出售合作约定范围外的流量或非法流量，比如：<br>
      a）向新量象出售非享有运营权的劫持流量；<br>
      b）向新量象出售黄赌毒等非法站点的流量；<br>
      c）未经事先书面确认，向新量象提供与合作约定不一致的流量； 
      5.1.4 其他可能损害新量象、新量象合作伙伴利益及终端用户体验的制造、提供虚假流量的行为。<br>
      5.2 新量象有权依据诸如流量来源、分布、留存、CTR（Click-Through-Rate）等数据制定虚假流量的判定标准，并对相关违规作弊行为进行独立认定。<br>
      5.3 若新量象认定用户存在提供虚假流量、违规作弊行为，将立即停止广告投放，并根据违规行为的具体情况采取以下一项或多项处理措施：<br>
      5.3.1 书面警告，以电子邮件、站内信的形式通知违规媒体纠正其违规行为； <br>
      5.3.2 封禁违规媒体账户，不予结算、支付违规行为所获得的全部收益； <br>
      5.3.3 以书面形式单方终止合作关系； <br>
      5.3.4 对新量象及新量象合作伙伴造成严重损失的，新量象将继续向媒体追偿； <br>
      5.3.5 在新量象平台上公示违规媒体信息； <br>
      5.3.6 其他依据新量象平台规则采取的措施。<br>
      5.4 若合作媒体对处理措施有异议的，应在知悉处理措施的3个工作日内，以电子邮件的形式向新量象提出，并根据新量象要求提供申诉材料。未按时提供申诉材料的，视为合作媒体接受相应的处理措施，新量象不再受理、回复该次申诉。<br>
      5.5 新量象接受申诉的电子邮箱为：fw@xinliangxiang.com。新量象将在收到申诉材料后的7个工作日内回复，新量象的回复结果即为最终结果。<br>
      5.6 每违规行为仅有一次申诉机会，请务必审慎提供申诉材料。 <br>
      5.7 因互联网广告行业快速发展，新量象有权根据平台运营安排、监管要求、行业动态等因素不时单方调整本处理规则，并以新量象平台网站公告、站内信、电子邮件等方式通知合作媒体。在此提醒，请您注意查看。<br>
      5.8 经调整的处理规则自通知规定的生效日起生效，您应当遵守。若您不同意处理规则的调整，应立即停止使用新量象平台，并书面通知新量象终止合作。您以任何方式继续使用新量象平台的，即视为认可并接受调整后的处理规则。<br>
      5.9 新量象变更作弊判定标准的，有权自监测软件新版本发布之日向前追溯30日。追溯期内，新量象有权按照新版本采用的作弊判定标准判定用户是否存在作弊行为，双方根据判定结果重新确认收益结算数据。<br>
      六、新量象的权利与义务 <br>
      6.1 新量象平台不含有任何破坏用户计算机数据和获取用户隐私信息的恶意代码，不含有任何跟踪、监视用户计算机和或操作行为的功能代码，不会监控用户网上、网下行为或泄露用户隐私。<br>
      6.2 新量象提供与新量象平台服务有关的技术支持或资料说明,并负责新量象平台的运营、维护。为向您提供更加优质的服务，新量象及其关联公司有权不断调整新量象平台及其具体服务，新量象平台及其具体服务名称、功能、域名的调整，不影响本注册协议的效力。<br>
      6.3 新量象有权根据单方运营安排随时调整或终止部分或全部的服务，包括但不限于停止特定服务、对某项服务进行升级、调整某项服务的功能或整合某些服务等。您有权根据自身需求决定是否继续使用相关服务。<br>
      6.4 若您存在违法违规或违反本注册协议约定的情况的，新量象有权根据一般人的认识自行独立判断，以认定您是否确实存在违法违规或违反本注册协议约定的情况；若新量象经过判断认为您确实存在违法违规或违反本注册协议约定的情况的，新量象有权随时单方对您（含您名下相关新量象平台账户）采取以下一项或多项限制措施，包括但不限于：立即停止广告投放、冻结合作位置、限制用户使用新量象服务、冻结、封停用户的新量象账户、终止合作等。 <br>
      6.5 如您受到主管部门调查或被第三方投诉，或您投诉第三方，新量象有权将争议中相关方的主体资料、联系方式、投诉相关内容等必要信息（包括但不限于名称、电话、证照等）提供给相关方或主管部门，以便及时解决投诉纠纷，保护各方合法权益。<br>
      七、收益结算 <br>
      7.1 您与新量象合作后，新量象应向您支付服务费。您同意，收益数据统计、计算方式以新量象平台统计并展示的结果为准。但您与新量象以书面形式另有约定的除外。<br>
      7.2 新量象平台每日统计并展示预计支付给您的服务费，您应登录新量象平台查看每日的预计服务费收入情况。在您未违反本注册协议、与新量象的合作协议的情况下，您有权按照新量象平台的统计、展示结果取得相应服务费。若您存在违约、违规情形，新量象将扣除违规行为所产生的服务费或违约金后，向您支付剩余金额（如有）。新量象向您实际支付的服务费金额以双方盖章确认的对账单为准。<br>
      7.3 收益结算的周期、支付方式等具体约定，以您与新量象公司以书面形式确认的为准。<br>
      八、知识产权及保密条款 <br>
      8.1 新量象拥有新量象平台的著作权、商标权、专利权、商业秘密以及其他相关的知识产权的合法权益，其它本注册协议中未经提及的权利亦由新量象保留。<br>
      8.2 新量象平台受到著作权法、商标法、专利法和其它知识产权法律或不正当竞争法律法规的保护，未经授权地复制、模仿、使用或发布上述标识，均被禁止，且应承担相关责任。未经新量象书面同意，用户不得为任何营利性或非营利性的目的自行实施、利用、转让或许可任何第三方实施、利用、转让上述知识产权，不得对新量象平台进行拷贝、复制、更改、修改或创作任何衍生作品的行为。<br>
      8.3 您与新量象合作中涉及的合作内容、素材及运营数据，未经新量象书面同意，您不得转让给任何第三方，不得利用新量象所提供的功能和信息从事任何与双方约定合作业务无关的商业活动。<br>
      九、信息保护和披露<br>
      我们非常重视您的信息安全。我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。关于信息保护和披露的具体内容，您可以详细阅读《新量象媒体隐私政策》。<br>
      十、违约责任 <br>
      10.1 本注册协议生效后，任何一方不履行其在本注册协议项下的任何义务，或违背其在本注册协议项下所作的任何承诺的，即构成违约。因此而给对方造成损失的，应予赔偿。<br>
      10.2 若您提交的注册信息和材料不真实、不完整、不合法或无效，那么导致或产生的一切法律责任由您承担。新量象有权随时封禁或删除您的新量象平台帐号，以及中止或终止为您提供新量象平台的相关服务。<br>
      10.3 若您在使用新量象平台过程中，违反本注册协议的约定，新量象有权按照本注册协议的约定采取一项或多项限制性措施。<br>
      十一、免责情形与责任限制 <br>
      11.1 因受不可抗力影响而不能履行或不能完全履行本注册协议的一方无需承担违约责任。但是，遇有不可抗力事件的一方，应立即将事件情况书面通知对方，并应于5个工作日内出示有效证明。双方按照事件对协议的履行的影响程度，再行协商决定是否继续履行本注册协议或终止协议。<br>
      11.2 新量象通过互联网信息技术为用户提供服务，新量象的免责事由包括但不限于下列任何影响新量象平台正常运营之情形，但新量象应及时与相关单位配合进行修复：<br>
      11.2.1 黑客攻击、计算机病毒侵入或发作； <br>
      11.2.2 基础运营商或主管部门技术调整导致的影响； <br>
      11.2.3 由于您的原因（包括但不限于操作失误、系统故障等）导致的； <br>
      11.2.4 计算机系统遭到破坏、瘫痪或无法正常使用导致新量象未能依约提供服务。 <br>
      11.2.5 因法律、法规、政策调整或政府管制（政府专项行动等）而造成的暂时性关闭、服务调整等；<br>
      11.2.6 其它非新量象过错造成的情形。 <br>
      十二、法律适用与争议解决 <br>
      12.1 本注册协议签订地为中华人民共和国浙江省杭州市西湖区。 <br>
      12.2 本注册协议的订立、履行、变更、终止、解除等一切问题均适用中华人民共和国大陆地区（仅为本注册协议之目的，不包括香港和澳门特别行政区和台湾）法律（不包括冲突法）。<br>
      12.3 若双方之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，双方在此完全同意将纠纷或争议提交协议签订地有管辖权的人民法院解决。<br>
      十三、其他 <br>
      13.1 新量象将以书面形式向您发出通知，书面形式包括但不限于：新量象平台网站公告、新量象平台站内信、向联系人手机发送短信、电子邮件、传真等电子方式，以及采取纸质函件邮寄的方式。在采用电子方式进行书面通知的情况下发送当日即视为送达，采取邮寄方式的，自寄出之日起第三个自然日即视为送达。新量象以多种方式向您发送通知的，通知送达时间以最先送达的为准。<br>
      13.2 本注册协议的标题仅为方便阅读所设，非对协议的定义、限制、解释或描述其范围或界限。<br>
      13.3 如本注册协议的任何条款被视作无效或无法执行，则除上述条款可被分离外，其余部分条款则仍具有法律效力。<br>
      13.4 新量象在新量象官网相关页面上的服务说明、价格说明和您确认同意的页面（包括特定功能的电子协议、页面提示文本、操作文档等）均为本注册协议不可分割的一部分。若前述内容与本注册协议不一致的，按照如下顺序优先适用：（1）电子协议（具体文案以签署时展示的为准）；（2）页面提示文本；（3）本注册协议。<br>
      13.5 一旦您在“我已阅读并同意《新量象平台媒体注册协议》”前打“√”或开始以其他方式使用新量象平台，本注册协议即生效，直至您注销新量象账户之日终止。<br>
      13.6 联系我们：您可以通过如下方式联系我们 联系电话：0571-89808775<br>
      电子邮箱：fw@xinliangxiang.com<br>
      公司地址：浙江省丽水市莲都区城北街368号绿谷信息产业园1号楼2楼201-1一般情况下，我们将在15个工作日内回复您。<br>
      (以下无正文) 杭州新量象网络科技有限公司<br>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header";
export default {
  components: {
    Header
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;
  height: 380px;
  background-image: url("../img/rule-bg.png");
  background-size: cover;
  margin-bottom: 58px;
}
.header-text {
  position: absolute;
  top: 170px;
  left: 13.5%;
}
.header-title {
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 30px;
  color: #fff;
  // font-family: "fzqk";
}
.header-sign {
  font-size: 24px;
  line-height: 24px;
  color: #fff;
}
.rule-content {
  width: 72.6%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 30px;
  color: rgba(102, 102, 102, 1);
}
@media screen and (max-width: 1440px) {
  .header {
    height: 285px;
  }
  .header-title {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .header-sign {
    font-size: 15px;
    line-height: 15px;
  }
}
</style>
